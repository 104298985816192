<script lang="ts" setup>
import axios from "axios";
import { useCookie } from "#app";
import { useUniversalPixel } from "~/composables/universalPixel";

useUniversalPixel().init();

const config = useRuntimeConfig();
if (process.client) {
    const hasToken = useCookie("XSRF-TOKEN");
    if (!hasToken) {
        getSessionCookie();
    }
}

async function getSessionCookie() {
    console.log("getting session cookie from default layout");

    const { data } = await axios.get(`${config.api}/sanctum/csrf-cookie`);
    return data;
}
</script>

<template>
    <div>
        <HeaderContent ref="header" />
        <main ref="mainContent" class="container relative min-h-screen pt-40 md:pt-44 lg:pt-48 print:pt-10">
            <slot />
        </main>
        <FooterContent />
    </div>
</template>
